import { globalLabels } from 'helpers/labels';
import React, { useEffect } from 'react';
import styles from './Offer.module.css'
import phone from '../../assets/icons/phone.svg'
import dumbell from '../../assets/icons/dumbell.svg'
import computer from '../../assets/icons/computer.svg'
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Offer = () => {

    const label = globalLabels.offer;

    useEffect(() => {
        Aos.init({
            delay: 300,
            duration: 1000,
        });
    }, []);

  return(
    <section className={styles.wrapper} id="offer">
        <div className={styles.contentWrapper}>

            <div>
                <img data-aos="fade-down" data-aos-delay="300" className={styles.icon} src={dumbell} alt="Dumbell icon" />
                <h3  data-aos="fade-up" data-aos-delay="300" className={styles.title}>{label.trening.title}</h3>
                <div
                    data-aos="fade-up" data-aos-delay="300"
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: label.trening.text }}
                />
            </div>

            <div>
                <img data-aos="fade-down" data-aos-delay="500" className={styles.icon} src={phone} alt="Phone icon" style={{ width: '45px'}} />
                <h3 data-aos="fade-up" data-aos-delay="500" className={styles.title}>{label.preparation.title}</h3>
                <div
                 data-aos="fade-up" data-aos-delay="500"
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: label.preparation.text }}
                />
                        <div>
            <a href="mailto:kontakt@szymanowicztrener.pl?subject=Trening%20personalny" className={styles.button}>
                Umawiam trening
            </a>
        </div>
            </div>

            <div>
                <img data-aos="fade-down" data-aos-delay="700" className={styles.icon} src={computer} alt="Computer icon" />
                <h3 data-aos="fade-up" data-aos-delay="700" className={styles.title}>{label.cooperation.title}</h3>
                <div
                    data-aos="fade-up" data-aos-delay="700"
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: label.cooperation.text }}
                />
            </div>
        </div>
    </section>
  );
};
