import React from 'react'
import styles from './About.module.css'

import sliderImg from '../../assets/main-slider2.png'
import { globalLabels } from 'helpers/labels';


const label = globalLabels.about;

export const About = () => {
    return (
        <section className={styles.wrapper} id="about">
            <div className={styles.container}>
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={sliderImg} alt="" />
                </div>

                <div className={`${styles.fadeUp}`}>
                    <div
                        className={`${styles.text}`}
                        dangerouslySetInnerHTML={{ __html: label.text }}
                    />

                    <div>
                        <a href="mailto:kontakt@szymanowicztrener.pl?subject=Trening%20personalny" className={styles.button}>
                            Napisz do mnie
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
