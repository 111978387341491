import React from 'react'
import { MainSlider } from 'components/MainSlider/MainSlider.layout'
import { About } from 'components/About/About.layout'
import { Offer } from 'components/Offer/Offer.layout'
import { Certificates } from 'components/Certificates/Certificates.layout'



export const MainPage = () => {
    return (
        <>
            <About />
            <Offer />
            <Certificates />
            <MainSlider />
        </>
    )
}
