import React from 'react'
import styles from './MainSlider.module.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { globalLabels } from 'helpers/labels';



export const MainSlider = () => {


    const slides = globalLabels.slides;

    return (
        <div className={styles.wrapper} id="mainSlider">
            <div className={styles.container}>
                <div className={styles.box}>
                    <Splide
                        options={{
                            rewind: true,
                            autoplay: true,
                            pagination: false,
                            interval: 6000,
                            rewindSpeed: 1000,
                        }}
                    >
                        <SplideSlide key="recommendation-1">
                            <div className={styles.slide}>
                                <p>
                                    Bardzo polecam trenera Mateusza. Jest to
                                    osoba, która ma ogromną wiedzę i cały czas
                                    się rozwija. Gram w piłkę nożną i dzięki
                                    pracy z nim zrobiłem spory postęp. Mateusz
                                    zwraca uwagę nawet na najmniejsze szczegóły,
                                    które naprawdę dają duży efekt. Bardzo
                                    angażuje się on w swojej pracy i w każdej
                                    chwili jest w stanie odpowiedzieć na pytania
                                    i pomóc.
                                </p>

                                <span>Damian Dunaj</span>
                            </div>
                        </SplideSlide>

                        <SplideSlide key="recommendation-2">
                            <div className={styles.slide}>
                                <p>
                                    Bardzo profesjonalne podejście do klienta !!
                                    Jestem piłkarką ręczną w zawodowym klubie i
                                    pierwszy raz do sezonu przygotowywałam się z
                                    Mateuszem 🙂 Treningi ułożone były pode
                                    mnie, abym poprawiła swoją skoczność,
                                    szybkość i siłę rzutu. Współpraca na miejscu
                                    jak i zdalnie przechodzi bezproblemowo 🙂 Z
                                    całego serduszka polecam ❤️
                                </p>

                                <span>Michalina Gryczewska</span>
                            </div>
                        </SplideSlide>
                    </Splide>

                    <a href="mailto:kontakt@szymanowicztrener.pl?subject=Trening%20personalny" className={styles.button}>
                        Umawiam trening
                    </a>
                </div>
            </div>
        </div>
    );
}
