import React from 'react'
import styles from './Footer.module.css';
import email from 'assets/icons/email.svg';
import facebook from 'assets/icons/facebook.svg';
import instagram from 'assets/icons/instagram.svg';

export const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.contactWrapper}>
                    <a href="mailto:kontakt@szymanowicztrener.pl">
                        <img src={email} alt="email" />
                    </a>
                    <a href="https://www.facebook.com/szymanowicz.trener/" target="_blank">
                        <img src={facebook} alt="facebook" style={{ width: '35px' }}/>
                    </a>
                    <a href="https://www.instagram.com/szymanowicz.trener/" target="_blank">
                        <img src={instagram} alt="instagram" />
                    </a>
                </div>
                <div>{currentYear}</div>
            </div>
        </div>
    );
};

