import React from "react";
import { MainPage } from "pages/MainPage/MainPage";
import { Menu } from "components/Menu/Menu.layout";
import { Footer } from "components/Footer/Footer.layout";


function App() {
	return (
		<div className="app">
			<Menu />
			<MainPage />
			<Footer />
		</div>
	);
}

export default App;
