import React from 'react'
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { globalLabels } from 'helpers/labels';
import styles from './Certificates.module.css';


const importAll = r => {
    let images = {};

    r.keys().map((item, index) => {
		images[item.replace('./', '')] = r(item);
	});

    return images;
}

// @ts-ignore
const images = importAll(require.context('../../../public/certyfikaty', false, /\.(png|jpe?g|svg)$/));

const galleryImages = Object.keys(images).map((image) => {
    return {
        original: `/certyfikaty/${image}`,
        thumbnail: `/certyfikaty/${image}`,
        loading: "lazy",
        thumbnailLoading: "lazy",
    };
});

const label = globalLabels.certificates;

export const Certificates = () => {
    return (
		<section id="certificates" className={styles.section}>
			<div className={styles.wrapper}>
				<h3 className={styles.title}>{label.title}</h3>

				<div style={{ padding: "30px 0 50px" }}>
					{/* @ts-ignore */}
					<ImageGallery items={galleryImages} />

					<div style={{ clear: "both" }}></div>
				</div>
			</div>
		</section>

    )
}
