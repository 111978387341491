import React, { useState } from "react";
import styles from "./Menu.module.css";
import logo from "assets/biale_png.png";
import { globalColors } from "helpers/colors";
import { globalLabels } from "helpers/labels";

export const Menu = () => {
    const [menuState, updateMenuState] = useState(false);

    const label = globalLabels.menu;

    const menuLabel = menuState ? "X" : "MENU";

    return (
        <div
            className={styles.wrapper}
            style={{ backgroundColor: globalColors.blue }}
        >
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img src={logo} alt="" />
                </div>

                <div
                    className={styles.menu}
                    onClick={() => updateMenuState(!menuState)}
                >
                    {menuLabel}
                </div>

                <div
                    className={`${styles.menuLists} ${
                        menuState ? styles.active : ""
                    }`}
                >
                    <div className={`${styles.menuContentWrapper}`}>
                        <a href="#about">{label.home}</a>
                        <a href="#offer">{label.oferta}</a>
                        <a href="#certificates">{label.certificates}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
