export const globalLabels = {
    menu: {
        home: 'home',
        oferta: 'oferta',
        kontakt: 'kontakt',
        about: 'o mnie',
        certificates: 'certyfikaty'
    },
    slides: {
        1: `“Dont hate me, hate the data” - Layne Norton`,
        2: `“You don't have to count calories, but calories will always count.” - Joseph Agu`,
        3: `“Strength is the mother of all qualities” - Tudor Bompa`,
        4: `„Maximal Strength is the Mother of all Strength Qualities„`,
        5: `Strength is the foundation for developing the rest of physical qualities - Leonid Matveyev`,
        6: `“Be whatever you want, but be strong first” - Leonid Matveyev`
    },
    offer: {
        trening: {
            title: 'Trening Personalny',
            text: `Pomogę Ci wypracować zdrowe i sprawne ciało, które nie tylko zachwyci swoim wyglądem, ale także gwarantuje długotrwałe dobre samopoczucie. Na bazie naszej pierwszej konsultacji dostosuję specjalny program treningowy do Twoich potrzeb, uwzględniając Twój aktualny poziom zaawansowania.<br>Podczas treningów skupię się na kształceniu poprawnej techniki ćwiczeń, co pozwoli Ci już od pierwszych powtórzeń podążać ścieżką do osiągnięcia swoich celów.`
        },
        preparation: {
            title: 'Przygotowanie Motoryczne',
            text: `W sporcie, o triumfie decydują często ułamki sekundy. Zwycięża ten, kto wykazuje się większą szybkością, siłą lub zwinnością w starciu z przeciwnikiem.<br><br>Dla każdego sportowca, dążącego do pełnego wykorzystania swojego potencjału, kluczowe jest zadbanie o odpowiednie przygotowanie fizyczne. Bez względu na to, czy trenujesz na poziomie zawodowym czy amatorskim, właściwie dostosowany trening dedykowany Twojej dyscyplinie pozwoli Ci wypracować przewagę nad rywalami, ograniczyć ryzyko kontuzji i wzmocnić pewność siebie podczas rywalizacji.`
        },
        cooperation: {
            title: 'Współpraca On-line',
            text: 'Jeżeli preferujesz samodzielne treningi i szukasz programu treningowego, proponuję również współpracę online. Po otrzymaniu od Ciebie wypełnionej ankiety oraz wyników przeprowadzonych testów, które pozwolą określić Twój aktualny poziom kondycji, przygotuję dla Ciebie spersonalizowany plan treningowy. Będzie on dostosowany do Twoich celów, dostępnego sprzętu oraz poziomu zaawansowania.'
        }
    },
    certificates: {
        title: "Certyfikaty"
    },
    about: {
        text:`Cześć!<br><br>Jestem Mateusz Szymanowicz trener przygotowania motorycznego. Współpracuje zarówno profesjonalnymi sportowcami, jak i ambitnymi amatorami, którzy dążą do osiągnięcia wyższego poziomu sportowego.<br><br>Jako trener, wykorzystuję moje doświadczenie, aby stopniowo rozwijać umiejętności moich podopiecznych w roli sportowców.<br><br>Oferuję wsparcie zarówno stacjonarnie, jak i w trybie online.`
    },
    page: {
        about: {
            title: "O mnie",
            text: "Zdaję sobie sprawę, że wejście na wyższy poziom sportowy może dla każdego oznaczać coś innego, dlatego podczas tworzenia treningów zawsze biorę pod uwagę indywidualne potrzeby i możliwości moich podopiecznych. Współpracuję z profesjonalistami z różnych dyscyplin, pasjonatami sportu oraz osobami, które pragną powrócić do pełnej sprawności fizycznej po kontuzji. Moim celem jest przekazywanie wiedzy i uświadamianie, że troszczenie się o zdrowie warto rozumieć holistycznie, patrząc wielowymiarowo na aspekt aktywności fizycznej. Jeśli masz pytania lub interesuje Cię współpraca, śmiało się ze mną skontaktuj!"
        }
    }
}